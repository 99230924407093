




















































































































































































































































































































































































































































































































































































































































































































































































































































.start_lci_button {
  background: #00563f;
  color: #fff;
  padding: 8px;
  border-radius: 10px;
  border-color: #00563f;
  min-width: 150px;
}
.submit_lci_buttons {
  background: #00563f;
}
.main_cards_container_lci {
  .every_lci_card {
    .icons_text_wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      background: #00563f;
      color: #fff;
      padding: 8px;
      border-radius: 10px;
      min-height: 177px;
      // min-height: 145px;
    }
    .img_icons {
      .img {
        max-width: 50px;
      }
    }
  }
}
::v-deep .el-radio-button {
  &.is-active {
    .el-radio-button__inner {
      background: #00563f;
      border-color: #00563f;
    }
  }
}
.icons_style {
  background-color: #eee;
  font-size: 14px;
  width: 30px;
  height: 30px;
  // box-shadow: $simple-shadow;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  cursor: pointer;
  color: #777;
  transition: 0.3s;
  &.speech {
    &:hover {
      background-color: #11bfe3;
      color: #fff;
    }
  }
}
// Start New Design For Lci
$just_theme_color: #00563f;
.main_new_lci_design {
  height: 150px;
  .user_bg_img {
    height: 150px;
    position: relative;
    .new_user_background {
      background-color: $just_theme_color;
      height: 100%;
      margin: auto;
    }
    .lines {
      width: 90%;
      border-radius: 50%;
      border: 3px solid #ffffff4d;
      bottom: -80px;
      position: absolute;
      height: 135px;
      left: 5%;
      display: block;
    }
    .user_img {
      width: 110px;
      height: 110px;
      position: absolute;
      bottom: -62px;
      left: calc(50% - 62px);
      border-radius: 50%;
      border: 2px solid #eee;
    }
  }
}
// Start Lci Cards
.main_new_title {
  margin: 40px auto 15px;
  color: #777;
  font-weight: bold;
  font-size: 20px;
}
.new_cms_cards {
  margin: 0 auto 20px;
  display: flex;
  width: 80%;

  gap: 4%;
  justify-content: space-between;
  flex-wrap: wrap;
  .lci_card_one {
    background-color: $just_theme_color;
    color: #fff;
    width: 48%;
    min-height: 140px;
    border-radius: 20px;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
  }
}
// Start New Content
.lci_new_content {
  $width: 25%;
  .login_what_patterns {
    margin-top: 90px;
    .text_patterns_box {
      width: 53%;
      margin: auto;
      text-align: center;
      font-size: 1.5rem;
      font-weight: bold;
      text-transform: uppercase;
      border: 5px solid transparent;
      color: #777;
      position: relative;
      padding: 10px;
      @media (min-width: 700px) and (max-width: 1200px) {
        width: 80%;
        font-size: 1.5rem;
      }
      @media (min-width: 460px) and (max-width: 700px) {
        width: 100%;
        font-size: 1.5rem;
      }
      @media (min-width: 320px) and (max-width: 460px) {
        width: 100%;
        font-size: 0.9rem;
      }
      // pat_text
      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 5px;
        background-color: #39c;
      }
      &::after {
        position: absolute;
        content: "";
        right: 0;
        top: 0;
        height: 100%;
        width: 5px;
        background-color: #c36;
      }

      .pat_one {
        position: absolute;
        top: -5px;
        left: 0;
        height: 5px;
        width: $width;
        background-color: #39c;
      }

      .pat_two {
        position: absolute;
        bottom: -5px;
        left: 0;
        height: 5px;
        width: $width;
        background-color: #39c;
      }
      .pat_three {
        position: absolute;
        top: -5px;
        left: $width;
        height: 5px;
        width: $width;
        background-color: #3c3;
      }
      .pat_four {
        position: absolute;
        bottom: -5px;
        left: $width;
        height: 5px;
        width: $width;
        background-color: #3c3;
      }

      .pat_five {
        position: absolute;
        top: -5px;
        left: $width * 2;
        height: 5px;
        width: $width;
        background-color: #f93;
      }
      .pat_six {
        position: absolute;
        bottom: -5px;
        left: $width * 2;
        height: 5px;
        width: $width;
        background-color: #f93;
      }

      .pat_seven {
        position: absolute;
        top: -5px;
        left: $width * 3;
        height: 5px;
        width: $width;
        background-color: #c36;
      }
      .pat_eight {
        position: absolute;
        bottom: -5px;
        left: $width * 3;
        height: 5px;
        width: $width;
        background-color: #c36;
      }
    }
    .titles_lci {
      width: 51%;
      margin: auto;
      text-align: center;
      font-size: 1.5rem;
      font-weight: bold;
      border: 3px solid $just_theme_color;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top: 0;
      color: #777;
      position: relative;
      padding: 10px;
      @media (min-width: 700px) and (max-width: 1200px) {
        width: 78%;
        font-size: 1.5rem;
      }
      @media (min-width: 460px) and (max-width: 700px) {
        width: 98%;
        font-size: 1.5rem;
      }
      @media (min-width: 320px) and (max-width: 460px) {
        width: 98%;
        font-size: 0.9rem;
      }
      h5 {
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 5px;
      }
      p {
        margin: 0;
        font-size: 15px;
        line-height: 1.5;
      }
    }
    .take_your_lci {
      width: 51%;
      display: block;
      margin: 15px auto 0;
      text-align: center;
      background-color: $just_theme_color;
      color: #fff;
      padding: 7px;
      font-weight: bold;
      border-radius: 7px;
      font-size: 14px;
      text-transform: uppercase;
      @media (min-width: 700px) and (max-width: 1200px) {
        width: 78%;
      }
      @media (min-width: 460px) and (max-width: 700px) {
        width: 98%;
      }
      @media (min-width: 320px) and (max-width: 460px) {
        width: 98%;
      }
    }
  }
}
